import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import { isOdd } from "../components/helpers";
import {
  darkPurple,
  innerWidth,
  mainWhite,
  mainPurple,
  screen,
  subFont,
  moveBgColor,
} from "../components/variables";
import CTA from "../components/cta";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Button from "../components/button";
import GeneralForm from "../components/form/general-form";

const Wrapper = styled.div`
  padding: 60px 0 0 0;
  @media ${screen.xsmall} {
    padding: 100px 0 0 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
      display: flex;
      align-items: center;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .hero {
    overflow: hidden;
    position: relative;
    @media ${screen.xsmall} {
      background-image: ${({ banner_background_image }) =>
        `url(${banner_background_image})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      min-height: 610px;
    }

    .gradient-bg {
      position: absolute;
      background-image: linear-gradient(110deg, #67b6ff, #3697ed, #6300ff);
      background-size: 350%;
      animation: ${moveBgColor} 5s infinite alternate;
      width: 100%;
      height: 100%;
      @media ${screen.xsmall} {
        position: absolute;
        width: 80%;
        height: 180%;
        left: -22%;
        top: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-wrapper {
      padding: 50px 25px 50px 25px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        width: 60%;
        padding: 0 80px 0 0;
      }

      .text-subwrapper {
        max-width: 710px;
        margin: 0 0 0 auto;
        @media ${screen.xsmall} {
          padding: 0 50px 0 40px;
        }
        @media ${screen.medium} {
          padding: 0 50px 0 0;
        }

        .description {
          margin: 22px 0 32px 0;
          position: relative;
          z-index: 2;
          color: ${mainWhite};

          h1 {
            font-family: ${subFont};
            font-size: 2.38rem;
            color: ${darkPurple};
            line-height: 1.05;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 3.7rem;
            }
            @media ${screen.small} {
              font-size: 3.9rem;
            }
            @media ${screen.medium} {
              font-size: 4.15rem;
            }

            em {
              color: ${mainWhite};
              font-style: normal;
            }

            strong {
              color: ${mainWhite};
              display: block;
              margin: 0 0 0 52px;
              @media ${screen.small} {
                margin: 0 0 0 152px;
              }
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: calc(50% + 125px);
      transform: translateY(-50%);
      left: -120px;
      pointer-events: none;
      width: 500px;
      @media ${screen.xsmall} {
        width: 950px;
        left: -400px;
        top: 50%;
      }
      @media ${screen.small} {
        width: 900px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 1000px;
      }
      @media ${screen.large} {
        width: calc(750px + 12vw);
        left: -70px;
      }
    }
  }

  .sections {
    padding: 30px 0;
    @media ${screen.xsmall} {
      padding: 40px 0;
    }
    @media ${screen.medium} {
      padding: 45px 0;
    }
  }

  .section {
    padding: 30px 0 55px 0;
    @media ${screen.xsmall} {
      padding: 40px 0 80px 0;
    }
    @media ${screen.medium} {
      padding: 45px 0 90px 0;
    }

    .col {
      &--img {
        @media ${screen.xsmall} {
          padding: 0 40px 0 0;
          width: 50%;
        }
        @media ${screen.small} {
          padding: 0 60px 0 0;
        }
        @media ${screen.medium} {
          padding: 0 80px 0 0;
        }
      }

      &--text {
        margin: 20px 0 0 0;
        @media ${screen.xsmall} {
          width: 50%;
          margin: 0;
        }

        .description {
          margin: 0 0 32px 0;

          h2 {
            font-family: ${subFont};
            font-size: 1.55rem;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              font-size: 1.8rem;
            }
            @media ${screen.small} {
              font-size: 1.9rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }

            strong {
              color: ${mainPurple};
              font-style: normal;
            }
          }

          p {
            font-size: 1.05rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 40px;
            @media ${screen.xsmall} {
              padding: 0 0 0 50px;
            }

            li {
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }

        .btn {
          a {
            background: ${darkPurple};
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainPurple};
              }
            }
          }
        }
      }
    }

    &--odd {
      .col {
        &--img {
          order: 2;

          @media ${screen.xsmall} {
            padding: 0 0 0 40px;
          }
          @media ${screen.small} {
            padding: 0 0 0 60px;
          }
          @media ${screen.medium} {
            padding: 0 0 0 80px;
          }
        }
      }
    }
  }
`;

const QuickstartPage = ({ data }) => {
  const [isGeneralForm, setIsGeneralForm] = useState(false);

  const {
    banner_background_image,
    banner_description,
    cta_background_image,
    cta_description,
    title_tag,
    main_rows,
    meta_description,
  } = data.main.data;

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={!!main_rows.length && main_rows[0]?.featured_image?.url}
      />
      <Wrapper banner_background_image={banner_background_image.url}>
        <header className="hero">
          <div className="gradient-bg" role="presentation"></div>

          <div className="text-wrapper">
            <div
              className="text-subwrapper"
              data-sal="slide-right"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-about-banner-arrows.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>
        </header>

        <div className="sections">
          {main_rows.map((item, i) => (
            <section
              className={isOdd(i) ? "section section--odd" : "section"}
              key={i}
            >
              <div className="inner-wrapper inner-wrapper--intro">
                <div
                  className="col col--img"
                  data-sal="slide-up"
                  data-sal-duration="500"
                >
                  <GatsbyImage
                    image={item.featured_image.gatsbyImageData}
                    alt={item.featured_image.alt || "Featured"}
                  />
                </div>
                <div
                  className="col col--text"
                  data-sal="slide-up"
                  data-sal-duration="500"
                >
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: item.description.html }}
                  />

                  {!!item.download_file.url && (
                    <Button
                      className="btn"
                      href={item.download_file.url}
                      isNewTab
                    >
                      DOWNLOAD PDF
                    </Button>
                  )}
                </div>
              </div>
            </section>
          ))}
        </div>

        <CTA
          bgImg={cta_background_image.url}
          className="cta"
          html={cta_description.html}
          setIsGeneralForm={setIsGeneralForm}
        />
      </Wrapper>

      <GeneralForm
        isGeneralForm={isGeneralForm}
        setIsGeneralForm={setIsGeneralForm}
      />
    </Layout>
  );
};

export default QuickstartPage;

export const dataQuery = graphql`
  {
    main: prismicQuickstartPage {
      data {
        banner_background_image {
          url
        }
        banner_description {
          html
        }
        main_rows {
          description {
            html
          }
          download_file {
            url
          }
          featured_image {
            alt
            url
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        cta_background_image {
          url
        }
        cta_description {
          html
        }
        title_tag
        meta_description
      }
    }
  }
`;
